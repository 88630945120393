/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
*/

@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import 'swiper/scss/zoom';
@import 'swiper/scss/thumbs';
@import '@ionic/angular/css/ionic-swiper';
@import '../node_modules/dragula/dist/dragula.css';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/font/Ubuntu-R.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
   }
   
   @font-face {
    font-family: 'Ubuntu';
    src: url('./assets/font/Ubuntu-RI.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
   }
   
   @font-face {
    font-family: 'Ubuntu';
    src: url('./assets/font/Ubuntu-B.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
   }
   
   @font-face {
    font-family: 'Ubuntu';
    src: url('./assets/font/Ubuntu-BI.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
   }
   
   :root {
    --background-hue: 225;
    --background-sat: 40%;
    --background-lig: 50%;
    --header-background: hsl(var(--background-hue), var(--background-sat), var(--background-lig));
    --body-background: hsl(var(--background-hue), calc(var(--background-sat) - 15%), calc(var(--background-lig) + 42%));
    --shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
   }
   
   textarea:focus, input:focus{
    outline: none;
  }

   body {
    font-family: Ubuntu, sans-serif;
    margin: 0px;
    background-color: var(--body-background);
    color: #000000;
    &.inApp{
      overflow: hidden;
    }
    &.photoslider{
      overflow: hidden;
      #content {
        overflow: hidden;
      }
    }
    &.pullToRefreshDisabled{
      overflow: hidden;
      overscroll-behavior: contain;
    }
    @media (max-width: 1100px) {
      &.showMenu{
        overflow: hidden;
      }
      &.mobile--overflow{
        overflow: hidden;
        #content {
          overflow: hidden;
        }
      }
    }
   }

  body app-root{
    left:0;
    top:0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
  }
   
   select, input, textarea{
    font-family: Ubuntu, sans-serif;
    background-color: #fff;
    color: #000;
   }
   button{
    font-family: Ubuntu, sans-serif;
   }
   select{
    cursor: pointer;
   }

   a {
    cursor: pointer;
   }
   
  .scrollbar {
    scrollbar-color: var(--header-background) var(--scrollbar-background);
    scrollbar-width: thin;
  }

  .scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: var(--scrollbar-background);
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--header-background);
    border-radius: 5px;
  }

   .clearfix {
    clear: both;
   }
   
   #header {
    width: 100%;
    color: #FFFFFF;
    @media (max-width: 1100px) {
      box-shadow: var(--shadow);
    }
   }
   
   #header .bar {
    margin: 0 auto;
    max-width: var(--page-max-width);
    background-color: var(--header-background);
   }
   
   #header .bar .icon, .header-cover .icon, #content .main-logo .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    width: var(--header-height);
    height: var(--header-height);
   }
   
   #header .bar .icon img, .header-cover .icon img , #content .main-logo .icon img{
    width: var(--header-icon-size);
    height: var(--header-icon-size);
   }
   
   #header .bar .text, .header-cover .text , #content .main-logo .text{
    font-size: calc(var(--header-icon-size) * 0.5);
    font-weight: bold;
    float: left;
   }
   .header_from_content{
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    z-index: 70;
    color: #fff;
    background: var(--header-background);
    box-shadow: var(--shadow);
    &_bar{
      margin: 0 auto;
      max-width: var(--page-max-width);
      position: relative;
    }

    .main-logo{
        padding-left: 10px;
        a{
          display: inline-flex;
          align-items: center;
        }
        @media (max-width: 1100px) {
          display: none;
        }
    }
  }
   
   .loader-wrap{
    width: 100%;
   }

   .gu-mirror{ 
      img{
        width: 65px;
        height: auto;
        border-radius: 10px;  
        overflow: hidden;
        box-shadow: var(--shadow);
      }
      &.other--bigger img{
        width: 141px;
      }
   }

   .errorMessage, .successMessage{
      background-color: #FFD6D0;
      color: #E31C18;
      display: flex;
      width: 290px;
      max-width: 100%;
      text-align: center;
      padding: 10px 15px;
      font-size: 15px;
      border-left: 10px solid #E31C18;
      align-items: center;
      min-height: 35px;
      position: relative;
      img{
        width: 13px;
        position: absolute;
        left: -37px;
      }
      span{
        text-align: left;
      }
   }

   .successMessage{
    background: #D6DBEF;
    color: var(--header-background);
    border-left: 10px solid var(--header-background);
   }

  ion-popover{
    --width: min(332px, 100vw);
    top: 5px;
    ion-list{
      padding: 0!important;
    }
    ion-item{
      --min-height: 30px;
    }
    &.popover-500{
      --width: min(500px, calc(100vw - 40px));
    }
  }

.login-form{
    ion-select {
      width: 330px;
      max-width: 100%;
      min-height: 40px;
      height: inherit;
      padding: 0;
      outline: 0;
      border: 1px solid #fff;
      
      background: #fff;
      color: #000;
      border-radius: 15px;
      --placeholder-color: #8B8F8B;
      --placeholder-opacity: 1;
      --padding-top: 0px;
      --padding-start: 15px;
      --padding-end: 15px;
      --min-height: 0px;
      --highlight-color-focused: transparent;
      --highlight-color-valid: transparent;
      --highlight-color-invalid: transparent;
      --highlight-color: transparent;
    }

    .meetrush-checkbox{
      a{
        color: #fff;
        &:hover{
          text-decoration: underline
          ;
        }
      }
    }
    
    ion-select::part(placeholder),
    ion-select::part(text) {
      flex: 0 0 auto;
      width: calc(100% - 10px);
    }
    
    ion-select::part(text) {
      color: #000;
    }
    
    ion-select::part(icon) {
      color: #000;
      opacity: 1;
    }
    ion-select::part(list) {
      padding: 0;
    }
}


.header-cover {
  width: 100%;
  height: var(--header-height);
  background-color: var(--header-background);
  color: #FFFFFF;
  box-shadow: var(--shadow);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  line-height: var(--header-height);
  display: none;
  &--desktop{
    display: flex;
    left: var(--menu-width);
  }
  &--no-shadow{
    box-shadow: none;
  }
  .text{
      text-align: left;
      flex: 1;
      margin-right: 50px;
      
      span, strong{
          display: inline-block;
      }
      span{
          font-size: 15px;
          font-weight: normal;
      }
  }
}

@media (max-width: 1100px) {
  .header-cover {
      display: flex;
      &--desktop{
        display: none;
      }
  }
}

.clickable{
  cursor: pointer;
}

ion-range{
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 0;
}
ion-range::part(tick) {
  background: var(--header-background);
  opacity: 0.5;
}

ion-range::part(tick-active) {
  background: var(--header-background);
  opacity: 0.8;
}

ion-range::part(pin) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--header-background);
  color: #fff;
  border-radius: 50%;
  transform: scale(1.01);
  top: 4px;
  min-width: 28px;
  height: 28px;
  transition: transform 120ms ease, background 120ms ease;
  z-index: 100;
}

.range-knob-handle ~ .range-knob-handle > .range-knob {
  background-color: #e6780b !important
}

ion-range::part(pin)::before {
  content: none;
}

ion-range::part(knob) {
  background: var(--header-background);
}

ion-range::part(bar-active) {
  background:  var(--header-background);
}

ion-checkbox{
  --background-checked: var(--header-background);
  --border-color-checked: var(--header-background);
  --border-color: var(--header-background);
}

.meetrush-checkbox{
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0px;
  height: 47px;
  ion-checkbox{
    pointer-events: none;
  }
}